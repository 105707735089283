<template>
  <div class="content" style="text-align: left">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>应试管理</BreadcrumbItem>
      <BreadcrumbItem :to="{ path: '/candidate/candidateManage' }">应试者管理</BreadcrumbItem>
      <BreadcrumbItem>{{ $route.query.name }} 的考试</BreadcrumbItem>
    </Breadcrumb>
    <div class="bg-shadow func-bar">
      <div class="button-container">
        <Button class="filter-btn btn-shadow" type="primary" @click="delExam">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-shanchu"></use>
          </svg>
          删除
        </Button>
      </div>
    </div>
    <div class="table-container bg-shadow">
      <Table class="flex-table" stripe :columns="columns" :data="tableData" :loading="loading" @on-selection-change="select" @on-row-click="toDetail">
        <template slot-scope="{ row, index }" slot="name">
          <span>{{ row.examName }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="status">
          <span>{{ status(row.status) }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="score">
          <span>{{ row.score }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="duration">
          <span>{{ timeStamp(row.duration) }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="expires">
          <span>{{ $formatTime(row.expiresTime, 'yyyy-MM-dd hh:mm') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="startTime">
          <span>{{ $formatTime(row.startTime, 'yyyy-MM-dd hh:mm') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="submit">
          <span>{{ $formatTime(row.submitTime, 'yyyy-MM-dd hh:mm') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="operation">
          <div>
            <Tooltip content="编辑" placement="top">
              <svg class="icon" aria-hidden="true" style="cursor: pointer" @click="edit(row)">
                <use xlink:href="#icon-bianji"></use>
              </svg>
            </Tooltip>
            <Tooltip content="删除" placement="top" style="margin-left: 10px">
              <svg class="icon" aria-hidden="true" style="cursor: pointer" @click="delCandidate(row.candidateId)">
                <use xlink:href="#icon-shanchu"></use>
              </svg>
            </Tooltip>
          </div>
        </template>
      </Table>
    </div>
    <Modal v-model="resModal" title="评测结果">
      <div v-for="(item, index) in result" :key="index" style="margin-top: 5px">
        <span style="font-size: 1.2em;font-weight: bold">{{ item.sectionName }}: {{ item.score }}分</span><br />
        <span style="font-size: 1.1em" v-html="item.result === null ? '' : item.result.replace(/(\r\n)|(\n)/g, '<br>')"></span>
      </div>
      <div slot="footer">
        <Button type="primary" @click="resModal = false">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import exam from '@api/exam'

export default {
  name: 'CandidateExam',
  data() {
    return {
      tableData: [],
      loading: false,
      resModal: false,
      result: [],
      selectIds: [],
      columns: [
        {
          type: 'selection',
          align: 'center',
          className: 'selection-column'
        },
        {
          title: '考试名称',
          slot: 'name',
          className: 'name-column'
        },
        {
          title: '状态',
          slot: 'status',
          className: 'name-column'
        },
        {
          title: '得分',
          slot: 'score',
          className: 'name-column'
        },
        {
          title: '用时',
          slot: 'duration',
          className: 'name-column'
        },
        {
          title: '失效时间',
          slot: 'expires',
          className: 'time-column'
        },
        {
          title: '开始时间',
          slot: 'startTime',
          className: 'time-column'
        },
        {
          title: '提交时间',
          slot: 'submit',
          className: 'time-column'
        }
      ]
    }
  },
  created() {
    this.initData()
  },
  methods: {
    toDetail(row) {
      if (row.type === 1) {
        this.$router.push({
          path: '/candidate/resultDetail',
          query: {
            name: this.$route.query.name,
            examName: row.examName,
            candidateExamId: this.$route.query.id,
            id: row.answerPaperId,
            score: row.score
          }
        })
      } else {
        this.result = []
        exam.getEvaRes(row.answerPaperId).then(res => {
          for (let i in res.res.evaluationResults) {
            this.result.push(res.res.evaluationResults[i])
          }
          this.resModal = true
        })
      }
    },
    delExam() {
      if (this.selectIds.length === 0) {
        this.$message.error('请选择要删除的考试')
      } else {
        this.$Modal.confirm({
          title: '提示',
          content: '<p>确认删除考试吗？</p>',
          loading: true,
          onOk: () => {
            let ids = []
            this.selectIds.map(item => {
              ids.push(item.answerPaperId)
            })
            exam.delCandidateExam(this.$route.query.id, ids).then(res => {
              if (res.res) {
                this.selectIds = []
                this.$Modal.remove()
                this.initData()
              }
            })
          }
        })
      }
    },
    select(val) {
      this.selectIds = val
    },
    timeStamp(second_time) {
      let time = parseInt(second_time) + '秒'
      if (parseInt(second_time) > 60) {
        let second = parseInt(second_time) % 60
        let min = parseInt(second_time / 60)
        time = min + '分' + second + '秒'

        if (min > 60) {
          min = parseInt(second_time / 60) % 60
          let hour = parseInt(parseInt(second_time / 60) / 60)
          time = hour + '小时' + min + '分' + second + '秒'

          if (hour > 24) {
            hour = parseInt(parseInt(second_time / 60) / 60) % 24
            let day = parseInt(parseInt(parseInt(second_time / 60) / 60) / 24)
            time = day + '天' + hour + '小时' + min + '分' + second + '秒'
          }
        }
      }
      return time === 'NaN秒' ? '' : time
    },
    status(status) {
      switch (status) {
        case 1:
          return '未开始'
        case 2:
          return '做题中'
        case 3:
          return '已暂停'
        case 4:
          return '已交卷'
        case 5:
          return '待批阅'
        case 6:
          return '已批阅'
      }
    },
    initData() {
      this.loading = true
      exam.getCandidateExam(this.$route.query.id).then(res => {
        this.tableData = res.res
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="less">
@import '../../../theme/variables';

.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.table-container {
  margin-top: 10px;
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .flex-table {
    border-bottom: 1px solid #e8eaec;

    .status-container {
      display: flex;
      align-items: center;

      .status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }

    .operation-tooltip {
      margin-right: 10px;
      cursor: pointer;
      color: @primary-color;
      transition: all @default-transition;

      &:hover {
        color: @primary-hover-color;
      }
    }

    .selection-column {
      width: 35px;
    }
    .name-column {
      width: 10%;
    }

    .time-column {
      width: 15%;
      flex: 1 1 auto;
    }

    .department-column {
      width: 15%;
      flex: 1 1 auto;
    }

    .email-column {
      width: 15%;
    }

    .phone-column {
      width: 15%;
    }

    .operation-column {
      width: 10%;
    }
  }
}
.func-bar {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 15px;
}
::v-deep .ivu-modal {
  .ivu-modal-body {
    padding: 0 50px 10px 50px;
  }
}
</style>
